export const enum WalletName {
  CASPER_SIGNER = 'Casper Signer',
  CASPER_WALLET = 'Casper Wallet',
  TORUS = 'Torus',
  MOCK = 'Mock',
  NONE = 'none',
}

export const enum Network {
  CASPER_TESTNET = 'casper-test',
  CASPER_MAINNET = 'casper',
  NONE = 'none',
}